<template>
    <button ref="btn" @click="goToBack" class="btn btn-back btn-icon btn-round" :style="MarginBottom ? 'margin-bottom:20px': ''">
        <span class="material-icons-outlined show-ltr" aria-hidden="true">arrow_back</span>
        <span v-if="false" class="material-icons-outlined show-rtl" aria-hidden="true">arrow_forward</span>        
    </button>
</template>

<script>
    export default {
        name: 'BackButton',
        props: {
            MarginBottom: {
                type: Boolean,
                default: false
            },
        },
        methods: {
            goToBack() {
                this.$router.go(-1);
            }
        },
        mounted() {
            if(this.backFocus == true) {
                setTimeout(() => {
                    this.$refs.btn.focus();
                }, 2000);
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import '@/scss/_variables.scss';
    @import '@/scss/_mixins.scss';

    .btn-back {
        box-shadow: 0 2px 4px 2px rgba(0,0,0,.25098039215686274);
        color: #3a3a3a;
        border: none;
        background-color: #fff;
        width: 40px!important;
        height: 40px!important;
        max-width: 40px !important;
        padding: 0px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin-right: 20px;

        @media(max-width:991px){
            display: none;
        }
    }


</style>
